<script setup lang="ts">
  import type { LinkProp } from '~/components/SideBar.vue';
  import Toaster from '@/components/ui/toast/Toaster.vue';

  const links: LinkProp[] = [
    {
      title: 'Dashboard',
      icon: 'lucide:home',
      variant: 'default',
      href: '/',
    },
    {
      title: 'Reservations',
      icon: 'lucide:calendar-clock',
      variant: 'default',
      href: '/reservations',
    },
    {
      title: 'Reports',
      icon: 'lucide:line-chart',
      variant: 'default',
      href: '/reports',
    },
    {
      title: 'Users',
      icon: 'lucide:users',
      variant: 'default',
      href: '/users',
    },
  ];

  const isCollapsed = ref(true);
</script>

<template>
  <div class="flex min-h-screen w-full flex-col bg-muted/40">
    <NuxtPwaManifest />
    <div class="flex flex-col sm:gap-4 sm:py-4" :class="[isCollapsed ? 'sm:pl-14' : 'sm:pl-56']">
      <SideBar :links="links" :is-collapsed="isCollapsed" />

      <TheHeading />
      <main class="flex flex-1 gap-4 p-4 sm:px-6 sm:py-0 md:gap-8">
        <div class="grow">
          <slot />
        </div>
      </main>
    </div>
    <Toaster />
    <ClientOnly>
      <LazyContentSearch />
    </ClientOnly>
    <UNotifications />
    <!--LayoutThePresenceIndicator /-->
  </div>
</template>

<style scoped></style>
