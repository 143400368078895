<script setup lang="ts">
  import { Icon } from '@iconify/vue';
  import { Sheet, SheetContent, SheetTrigger } from '@/components/ui/sheet';
  import { Button } from '@/components/ui/button';
  import {
    DropdownMenu,
    DropdownMenuItem,
    DropdownMenuLabel,
    DropdownMenuSeparator,
    DropdownMenuContent,
    DropdownMenuTrigger,
  } from '@/components/ui/dropdown-menu';

  defineProps<{
    isCollapsed?: boolean;
  }>();

  const colorMode = useColorMode();
  const { user, logout } = useSanctumAuth();
</script>

<template>
  <header
    class="sticky top-0 z-30 flex h-14 items-center gap-4 border-b bg-background px-4 sm:static sm:h-auto sm:border-0 sm:bg-transparent sm:px-6"
  >
    <Sheet>
      <SheetTrigger as-child>
        <Button size="icon" variant="outline" class="sm:hidden">
          <Icon icon="lucide:panel-left" class="h-5 w-5" />
          <span class="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" class="sm:max-w-xs">
        <nav class="grid gap-6 text-lg font-medium">
          <a
            href="#"
            class="group flex h-10 w-10 shrink-0 items-center justify-center gap-2 rounded-full bg-primary text-lg font-semibold text-primary-foreground md:text-base"
          >
            <Icon icon="lucide:package" class="h-5 w-5 transition-all group-hover:scale-110" />
            <span class="sr-only">Acme Inc</span>
          </a>
          <a
            href="#"
            class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
          >
            <Icon icon="lucide:home" class="h-5 w-5" />
            Dashboard
          </a>
          <a
            href="#"
            class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
          >
            <Icon icon="lucide:shopping-cart" class="h-5 w-5" />
            Orders
          </a>
          <a href="#" class="flex items-center gap-4 px-2.5 text-foreground">
            <Icon icon="lucide:package" class="h-5 w-5" />
            Products
          </a>
          <a
            href="#"
            class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
          >
            <Icon icon="lucide:users" class="h-5 w-5" />
            Customers
          </a>
          <a
            href="#"
            class="flex items-center gap-4 px-2.5 text-muted-foreground hover:text-foreground"
          >
            <Icon icon="lucide:settings" class="h-5 w-5" />
            Settings
          </a>
        </nav>
      </SheetContent>
    </Sheet>
    <DashboardBreadcrumb />
    <div class="relative ml-auto flex-1 md:grow-0">
      <ContentSearchButton />
    </div>
    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <Button variant="outline">
          <Icon
            icon="radix-icons:moon"
            class="h-[1.2rem] w-[1.2rem] rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0"
          />
          <Icon
            icon="radix-icons:sun"
            class="absolute h-[1.2rem] w-[1.2rem] rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100"
          />
          <span class="sr-only">Toggle theme</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuItem @click="colorMode.preference = 'light'"> Light </DropdownMenuItem>
        <DropdownMenuItem @click="colorMode.preference = 'dark'"> Dark </DropdownMenuItem>
        <DropdownMenuItem @click="colorMode.preference = 'system'"> System </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
    <DropdownMenu>
      <DropdownMenuTrigger as-child>
        <Button variant="secondary" size="icon" class="rounded-full">
          <UAvatar
            :alt="user.name"
            size="sm"
            :ui="{
              placeholder: 'text-red-600',
            }"
          />
          <span class="sr-only">Toggle user menu</span>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>My Account</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuItem>Settings</DropdownMenuItem>
        <DropdownMenuItem>Support</DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem class="cursor-pointer" @click="logout">Logout</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  </header>
</template>

<style scoped></style>
